const bio = {
  title: 'About',
  age: '39',
  pob: 'Zurich, Switzerland',
  nationality: 'Swiss',
  interests: [
    'Music (metal, rock\'n\'roll, prog)',
    'Political science',
    'Languages',
  ],
};

export default bio;
